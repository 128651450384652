<template>
  <div id="bidInfo">
    <!-- 横幅图  -->
    <div class="multiple_banner" v-for="item in banners" :key="item.id" v-show="tabIndex === item.id">
      <el-image style="width: 100%; height: 219px; border: 0" :src="item.url" fit="fill" />
    </div>
    <!--  主体内容  -->
    <div class="multiple_main">
      <div class="multiple_warp">
        <!--    主体标题    -->
        <div class="multiple_title">
          <div class="multiple_title_main">{{ tabs[tabIndex].title }}</div>
          <div class="multiple_title_path">网站首页/招标信息/{{ tabs[tabIndex].title }}</div>
        </div>
        <div class="multiple_content">
          <!--    tab切换      -->
          <div class="multiple_content_left">
            <div class="multiple_item_warp" v-for="item in tabs" :key="item.id" :class="[tabIndex === item.id ? 'multipleItemWarpAction' : '']" @click="multipleTabClick(item)">
              {{ item.title }}
            </div>
          </div>
          <!--    tab切换内容      -->
          <div class="multiple_content_right">
            <div class="list_title_warp">
              <div class="list_title_txt">
                {{ tabs[tabIndex].title }}
              </div>
              <div class="list_title_null"></div>
            </div>
            <!--     内容数据       -->
            <div class="content_list_warp">
              <div class="list_item_warp" v-for="item in data" :key="item.id">
                <el-link @click="articleClick(item.id)" :underline="false">
                  <el-card class="box-card" shadow="hover" style="width: 100%; height: 161px">
                    <div class="item_warp_content">
                      <div class="item_warp_left">
                        <el-image style="width: 200px; height: 150px; border: 0" :src="preview + item.fileId" fit="fill">
                          <div slot="error" class="image-slot">
                            <el-image style="width: 200px; height: 150px; border: 0" :src="defalutUrl" fit="fill" />
                          </div>
                        </el-image>
                      </div>
                      <div class="item_warp_right">
                        <div class="list_item_title">{{ item.arTitle }}</div>
                        <div class="list_item_time">{{ item.releaseTime }}</div>
                        <div class="list_item_summary">{{ item.arAbstract }}</div>
                      </div>
                    </div>
                  </el-card>
                </el-link>
              </div>
            </div>
            <!--    内容分页      -->
            <div class="content_pages_warp">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :hide-on-single-page="true" :page-size="10" layout="prev, pager, next, jumper" :total="total"> </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "bidInfo",
  data() {
    return {
      defalutUrl: require("../../public/statics/error/zwtp.jpg"),
      //横幅图
      banners: [
        {
          id: 0,
          url: "/statics/banner/bidInfo/zhaobxx.png"
        },
        {
          id: 1,
          url: "/statics/banner/bidInfo/zhongbxx.png"
        }
      ],
      //tab切换下标
      tabIndex: 0,
      //tab切换
      tabs: [
        {
          id: 0,
          title: "招标信息",
          arCatalog: "bidding_information"
        },
        {
          id: 1,
          title: "中标信息",
          arCatalog: "bid_winning_information"
        }
      ],
      //文章类型
      arCatalog: "bidding_information",
      //数据
      data: [],
      //总数
      total: 0
    }
  },
  watch: {
    //监听路由变化
    $route: {
      handler() {
        this.tabIndex = 0
        this.arCatalog = "bidding_information"
        this.getBanners()
        // this.getData(1, "bidding_information");

        this.initialize()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    //获取横幅图
    async getBanners() {
      let banners1 = await this.request.get("/banner/getInfo?key=" + this.config.key + "&bannerType=12")
      if (banners1.data && banners1.data.fileId) {
        this.banners[0].url = this.preview + banners1.data.fileId
      }
      let banners2 = await this.request.get("/banner/getInfo?key=" + this.config.key + "&bannerType=13")
      if (banners2.data && banners2.data.fileId) {
        this.banners[1].url = this.preview + banners2.data.fileId
      }
    },
    //初始化参数
    initialize() {
      let arCatalog = this.$route.query.arCatalog
      console.log(arCatalog)
      switch (arCatalog) {
        case "bidding_information":
          this.getData(1, "bidding_information")

          this.tabIndex = 0
          break
        case "bid_winning_information":
          this.getData(1, "bid_winning_information")
          this.tabIndex = 1
          break
        default:
          this.tabIndex = 0
          this.getData(1, "bidding_information")
      }
    },
    //文章详情点击（包括：公司新闻、项目动态、党建专栏、项目成果、行政法规、行政动态、招标信息、中标信息）
    articleClick(id) {
      this.$router.push({ path: "/article", query: { active: 6, id: id } })
    },
    //tab点击切换事件
    multipleTabClick(item) {
      this.tabIndex = item.id
      this.arCatalog = item.arCatalog
      this.getData(1, this.arCatalog)
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      this.getData(val, this.arCatalog)
    },
    //获取数据
    getData(val, arCatalog) {
      this.arCatalog = arCatalog
      this.request.get("/article/list?key=" + this.config.key + "&pageNum=" + val + "&pageSize=10&arCatalog=" + this.arCatalog).then((res) => {
        this.data = res.data
        this.total = res.total
      })
    }
  }
}
</script>
<style>
@import "../assets/styles/pages/multiple.css";
</style>
